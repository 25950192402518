export interface Field {
  category: string
  subCategory?: string
  value: FieldPreset
  name: string
  customFieldId?: string
  isPremium?: boolean
  dependsOn?: string[]
  tooltip?: string
  crmType?: string
}

export interface MissingField {
  type: FieldNameType
  name: string
}

export enum FieldNameType {
  ROLE = 'ROLE',
  PRESET = 'PRESET'
}

export enum MessageType {
  SUCCESS = 'SUCCESS',
  DOWNLOAD = 'DOWNLOAD',
  REGISTRATION = 'REGISTRATION',
}

export enum FIELD_COMPONENT_TYPES {
  TEXT_INPUT = 'wysiwyg.viewer.components.inputs.TextInput',
  RADIO_GROUP = 'wysiwyg.viewer.components.inputs.RadioGroup',
  DATE_PICKER = 'wysiwyg.viewer.components.inputs.DatePicker',
  COMBOBOX = 'wysiwyg.viewer.components.inputs.ComboBoxInput',
  TEXT_AREA_INPUT = 'wysiwyg.viewer.components.inputs.TextAreaInput',
  FILE_UPLOADER = 'wysiwyg.viewer.components.inputs.FileUploader',
  CHECKBOX_GROUP = 'wysiwyg.viewer.components.inputs.CheckboxGroup',
  SINGLE_CHECKBOX = 'wysiwyg.viewer.components.inputs.Checkbox',
  RICH_TEXT = 'wysiwyg.viewer.components.WRichText',
  RATING = 'wixui.RatingsInput',
  RECAPTCHA = 'wixui.Captcha',
  SIGNATURE_INPUT = 'wixui.SignatureInput',
  TIME_PICKER = 'wixui.TimePicker',
}

export type FieldPreset = FormsFieldPreset | AdiFieldPreset | RegistrationFieldPreset

export enum FormsFieldPreset {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  COMPANY = 'company',
  POSITION = 'position',
  EMAIL = 'email',
  ADDRESS = 'address',
  PHONE = 'phone',
  BIRTHDAY = 'birthday',
  ANNIVERSARY = 'anniversary',
  WEBSITE = 'website',
  AGREE_TERMS = 'agreeTerms',
  GENERAL_TEXT = 'generalText',
  GENERAL_NUMBER = 'generalNumber',
  GENERAL_URL = 'generalURL',
  GENERAL_RADIO_BUTTON = 'generalRadioButton',
  GENERAL_DROP_DOWN = 'generalDropDown',
  GENERAL_CHECKBOX = 'generalCheckbox',
  GENERAL_SINGLE_CHECKBOX = 'generalSingleCheckbox',
  GENERAL_SUBSCRIBE = 'generalSubscribe',
  GENERAL_TEXT_BOX = 'generalTextBox',
  GENERAL_UPLOAD_BUTTON = 'generalUploadButton',
  GENERAL_DATE_PICKER = 'generalDatePicker',
  GENERAL_RATING = 'generalRating',
  GENERAL_RECAPTCHA = 'generalRecaptcha',
  GENERAL_SIGNATURE = 'generalSignature',
  GENERAL_TIME_PICKER = 'generalTimePicker',
  CRM_TEXT = 'crmText',
  CRM_NUMBER = 'crmNumber',
  CRM_URL = 'crmURL',
  CRM_DATE = 'crmDate',
  NONE = '',
}

export enum AdiFieldPreset {
  ADI_EMAIL = 'adiEmail',
  ADI_ADDRESS = 'adiAddress',
  ADI_PHONE = 'adiPhone',
  ADI_URL = 'adiURL',
  ADI_DATE = 'adiDate',
  ADI_NUMBER = 'adiNumber',
  ADI_FIRST_NAME = 'adiFirstName',
  ADI_LAST_NAME = 'adiLastName',
  ADI_GENERAL_TEXT = 'adiGeneralText',
  ADI_GENERAL_TEXT_BOX = 'adiGeneralTextBox',
  ADI_UPLOAD_BUTTON = 'adiGeneralUploadButton',
  ADI_GENERAL_DROPDOWN = 'adiGeneralDropDown',
}

export enum RegistrationFieldPreset {
  REGISTRATION_FORM_LINK_TO_LOGIN = 'regForm_linkToLoginDialog',
  REGISTRATION_FORM_PRIVACY_POLICY = 'regForm_privacyPolicy',
  REGISTRATION_FORM_CODE_OF_CONDUCT = 'regForm_codeOfConduct',
  REGISTRATION_FORM_LOGIN_EMAIL = 'regForm_loginEmail',
  REGISTRATION_FORM_PASSWORD = 'regForm_password',
  REGISTRATION_FORM_CHECKBOX_AGREE_TERMS = 'regForm_checkboxAgreeTerms',
  REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY = 'regForm_checkboxJoinCommunity',
}

export enum customTypes {
  TEXT = 'Text',
  NUMBER = 'Number',
  URL = 'URL',
  DATE = 'Date',
}

export type CustomField = { 
  name: string
  fieldType: customTypes
}

export type CustomFieldResponse = { 
  id: string
  name: string
  fieldType: customTypes
}

export const CRM_LABEL_MAX_LENGTH = 29
