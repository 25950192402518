import {
  ROLE_SUBMIT_BUTTON,
  ROLE_PREVIOUS_BUTTON,
  ROLE_NEXT_BUTTON,
  STEP_ROLE,
  THANK_YOU_STEP_ROLE,
} from '../constants/roles'
import {
  isCheckbox,
  isSignatureField,
  isUploadButton,
  isCaptchaField,
  isDatePicker,
  shouldSendData,
  getFieldValueByCrmType,
  isTimePicker,
  getBaseUrl,
} from './viewer-utils'
import * as _ from 'lodash'
import { EVENTS } from '../constants/bi-viewer'
import { Attachment } from './field-dto/field-dto'
import { post } from './services/fetch-utils'
import { CRM_TYPES } from '../constants/crm-types-tags'
import { siteStore } from './stores/site-store'
import { UploadFileError } from './errors'
import { UploadSignatureResponse, SubmitFormResponse } from '../types/domain-types'

class SubmitUtils {
  public getSubmitButton($w) {
    return $w(`@${ROLE_SUBMIT_BUTTON}`)[0]
  }

  public getPreviousButtons($w) {
    return $w(`@${ROLE_PREVIOUS_BUTTON}`)
  }

  public getNextButtons($w) {
    return $w(`@${ROLE_NEXT_BUTTON}`)
  }

  public getThanksStep($w) {
    return $w(`@${THANK_YOU_STEP_ROLE}`)
  }

  public getFields({ $w, roles }): any {
    const fields = roles.reduce((res, roleField) => res.concat($w(`@${roleField}`)), [])
    return _.uniqBy(fields, (field: { uniqueId: string }) => field.uniqueId)
  }

  public validateFields({ fields, strategy }): any[] {
    fields.forEach(field => field.updateValidityIndication && field.updateValidityIndication())
    const invalidFields = strategy.validateFields(fields)

    if (invalidFields.length > 0) {
      if (invalidFields[0].scrollTo) {
        invalidFields[0].scrollTo()
      }
    }

    return invalidFields
  }

  public async getAttachments(fields): Promise<Attachment[]> {
    const uploadButtons = fields.filter(field => isUploadButton(field) && field.value.length > 0)

    if (!uploadButtons.length) {
      return Promise.resolve([])
    }

    siteStore.interactionStarted('upload-files')

    try {
      const attachments: Attachment[] = await Promise.all(
        uploadButtons.map(
          async (uploadButtonField): Promise<Attachment> => {
            const { url } = await uploadButtonField.startUpload()

            return {
              url,
              name: uploadButtonField.value[0].name,
              uniqueId: uploadButtonField.uniqueId,
            }
          }
        )
      )

      siteStore.interactionEnded('upload-files')

      return attachments
    } catch (err) {
      throw new UploadFileError(err)
    }
  }

  private async _startSignatureUpload({ form, signatureField, name }): Promise<Attachment> {
    const { value, uniqueId } = signatureField
    const baseUrl = getBaseUrl()

    const data = await post<UploadSignatureResponse>(baseUrl, '_api/wix-forms/v1/media/signature', {
      formId: form.uniqueId,
      signature: value,
      namePrefix: name,
    })

    return {
      url: data.url,
      name: data.name,
      uniqueId: uniqueId,
    }
  }

  private async _getSignatureName(fields) {
    return (
      getFieldValueByCrmType(fields, CRM_TYPES.LAST_NAME) ||
      getFieldValueByCrmType(fields, CRM_TYPES.EMAIL) ||
      getFieldValueByCrmType(fields, CRM_TYPES.FIRST_NAME) ||
      ''
    )
  }

  public async getSignatureAttachments({ fields, form }): Promise<Attachment[]> {
    const signatureFields = fields.filter(
      field => isSignatureField(field) && field.value.length > 0
    )

    if (!signatureFields.length) {
      return Promise.resolve([])
    }

    siteStore.interactionStarted('upload-signatures')

    const signatureName = await this._getSignatureName(fields)

    const data: Attachment[] = await Promise.all(
      signatureFields.map(signatureField =>
        this._startSignatureUpload({
          form,
          signatureField,
          name: signatureName,
        })
      )
    )

    siteStore.interactionEnded('upload-signatures')

    return data
  }

  public async sendFieldsToServer({
    strategy,
    attachments,
    fields,
    viewMode,
  }): Promise<SubmitFormResponse> {
    return strategy.execute({ attachments, fields, viewMode })
  }

  private _getCurrentPageName({ wixSite, wixLocation }) {
    const siteStructure = wixSite.getSiteStructure()

    const currentPath = wixLocation.path

    let currentPageName

    const validPageUrl = _.findLast(currentPath, url => !_.isEmpty(_.trim(url)))

    if (validPageUrl) {
      const currentPageStructure = _.find(siteStructure.pages, ['url', `/${validPageUrl}`])
      currentPageName = _.get(currentPageStructure, 'name')
    } else {
      const homePageStructure = _.find(siteStructure.pages, ['isHomePage', true])
      currentPageName = _.get(homePageStructure, 'name')
    }

    return currentPageName
  }

  public sendWixAnalytics({ wixSite, wixLocation, wixWindow }) {
    const currentPageName = this._getCurrentPageName({ wixSite, wixLocation })

    if (!currentPageName) return

    wixWindow.trackEvent('Lead', {
      label: `Page Name: ${currentPageName}`,
    })
  }

  public resetFields(fields, initialFields) {
    fields.forEach(field => {
      if (isUploadButton(field) || isCaptchaField(field)) {
        if ('reset' in field) {
          field.reset()
        }
        return
      }

      if (isSignatureField(field)) {
        field.clear()
        return
      }

      if (isTimePicker(field)) {
        const initialTimeField = _.find(initialFields, { uniqueId: field.uniqueId })
        field.value = initialTimeField.value || ''
        return
      }

      if (isCheckbox(field)) {
        field.checked = false
      } else {
        field.value = null
      }

      if ('resetValidityIndication' in field) {
        field.resetValidityIndication()
      }
    })
  }

  public navigateToStepByOffset = ($multiStepForm, offsetIndex: number) => {
    const states = $multiStepForm.states
    const stepsOrderIds = _.get($multiStepForm, 'connectionConfig.stepsOrderIds')

    let nextState

    if (stepsOrderIds) {
      const currentStateIndex = _.findIndex(
        stepsOrderIds,
        stepId => stepId === $multiStepForm.currentState.uniqueId
      )

      const nextStateId = stepsOrderIds[currentStateIndex + offsetIndex]
      nextState = _.find(states, { uniqueId: nextStateId })
    }

    if (!nextState) {
      const stateIdx = this._getCurrentStateIdx($multiStepForm, states)
      nextState = states[stateIdx + offsetIndex]
    }

    if (nextState) {
      return $multiStepForm.changeState(nextState)
    }
  }

  public async navigateToNextStep(
    { $w, $nextButton, $multiStepForm },
    {
      onNavigationEnd,
      getCurrentFields,
      uploadFields,
      saveDatePickersInState,
      fillDatePickersInState,
      strategy,
      wixLocation,
    }
  ) {
    siteStore.interactionStarted('next-step')
    $nextButton.disable()
    siteStore.log(this._getStepParamsForBi($w, $multiStepForm, 'next'))
    await onNavigationEnd
    const fields = getCurrentFields()
    const invalidFieldsInStep = this.validateFields({
      fields,
      strategy,
    })

    if (invalidFieldsInStep.length === 0) {
      if (shouldSendData(wixLocation)) {
        await uploadFields(fields)
      }
      saveDatePickersInState()
      await this.navigateToStepByOffset($multiStepForm, 1)
      fillDatePickersInState()
    }

    $nextButton.enable()
    siteStore.interactionEnded('next-step')
  }

  public async navigateToPreviousStep(
    { $w, $previousButton, $multiStepForm },
    { onNavigationEnd, saveDatePickersInState, fillDatePickersInState }
  ) {
    siteStore.interactionStarted('previous-step')
    $previousButton.disable()
    siteStore.log(this._getStepParamsForBi($w, $multiStepForm, 'back'))
    await onNavigationEnd
    saveDatePickersInState()
    await this.navigateToStepByOffset($multiStepForm, -1)
    fillDatePickersInState()
    $previousButton.enable()
    siteStore.interactionEnded('previous-step')
  }

  public registerFieldsToStates(
    multiStepForm,
    thanksStep,
    fields: any[],
    onNavigationEnd: Promise<void>
  ): {
    getCurrentFields: () => any
    getAllFields: () => any[]
    saveDatePickersInState: (fields: any[]) => void
    fillDatePickersInState: (fields: any[]) => void
    fillFieldsValues: (fields: any[]) => void
  } {
    const statesFields: { [uniqueId: string]: any[] } = multiStepForm.states.reduce(
      (acc, { uniqueId }) => {
        acc[uniqueId] = []
        return acc
      },
      {}
    )

    fields.forEach(field => {
      field.onViewportEnter(async () => {
        await onNavigationEnd
        const currentStateId: string = multiStepForm.currentState.uniqueId
        const isFieldExists: boolean = !!_.find(statesFields[currentStateId], [
          'uniqueId',
          field.uniqueId,
        ])
        if (!isFieldExists) {
          statesFields[currentStateId] = statesFields[currentStateId].concat(field)
        }
      })
    })

    const datePickerValues: {
      [uniqueId: string]: string
    } = {}

    const saveDatePickersInState = () => {
      statesFields[multiStepForm.currentState.uniqueId]
        .filter(isDatePicker)
        .forEach(({ value, uniqueId }) => (datePickerValues[uniqueId] = value))
    }
    const fillDatePickersInState = () => {
      fillFieldsValues(statesFields[multiStepForm.currentState.uniqueId])
    }
    const fillFieldsValues = (fields: any[]) => {
      fields.filter(isDatePicker).forEach(field => {
        field.value = datePickerValues[field.uniqueId] || field.value
      })
    }

    return {
      getCurrentFields: () => statesFields[multiStepForm.currentState.uniqueId],
      getAllFields: () => _.flatten(_.values(_.omit(statesFields, thanksStep.uniqueId))),
      saveDatePickersInState,
      fillDatePickersInState,
      fillFieldsValues,
    }
  }

  public registerFieldsToCache({
    form,
  }): {
    uploadFields: (fields: any[]) => Promise<void>
    getUploadFieldsData: () => Attachment[]
  } {
    const fieldsUploadData: {
      [uniqueId: string]: Attachment
    } = {}
    const uploadFields = async fields => {
      const unsavedFields = fields.filter(({ uniqueId, value }) => {
        return (
          !fieldsUploadData[uniqueId] ||
          (value[0] && value[0].name !== fieldsUploadData[uniqueId].name)
        )
      })

      if (unsavedFields.length) {
        const attachments = await this.getAttachments(unsavedFields)

        attachments.forEach(attachment => {
          fieldsUploadData[attachment.uniqueId] = attachment
        })
      }

      // Upload signature attachments for every step change
      const signatureAttachments = await this.getSignatureAttachments({
        fields,
        form,
      })

      signatureAttachments.forEach(attachment => {
        fieldsUploadData[attachment.uniqueId] = attachment
      })

      // Remove fields cached data for signature fields when current signature field is empty and was signed before
      fields.forEach(field => {
        const isSignatureFieldPreviouslySigned =
          fieldsUploadData[field.uniqueId] && isSignatureField(field) && field.value === ''

        if (isSignatureFieldPreviouslySigned) {
          delete fieldsUploadData[field.uniqueId]
        }
      })
    }
    const getUploadFieldsData = () => _.values(fieldsUploadData)

    return {
      uploadFields,
      getUploadFieldsData,
    }
  }

  private _getStepParamsForBi($w, $multiStepForm, action) {
    const steps = $w(`@${STEP_ROLE}`)
    const stateIdx = this._getCurrentStateIdx($multiStepForm, steps)
    return {
      evid: EVENTS.USER_CLICKS_NAVIGATION_BUTTONS,
      form_comp_id: $multiStepForm.uniqueId,
      step_no: stateIdx + 1,
      step_name: steps[stateIdx].connectionConfig.title,
      action,
      total_number_of_steps: steps.length,
    }
  }

  private _getCurrentStateIdx($multiStepForm, states) {
    return _.findIndex(states, {
      uniqueId: $multiStepForm.currentState.uniqueId,
    })
  }
}

export const submitUtils = new SubmitUtils()
